// extracted by mini-css-extract-plugin
export var buttonArticles = "Post-module--buttonArticles--d793a";
export var buttons = "Post-module--buttons--2972d";
export var comments = "Post-module--comments--d3b99";
export var content = "Post-module--content--3c6e5";
export var fadeaway = "Post-module--fadeaway--c4aa7";
export var fadein = "Post-module--fadein--ea63d";
export var footer = "Post-module--footer--f8705";
export var justfade = "Post-module--justfade--e0547";
export var justpushup = "Post-module--justpushup--78718";
export var post = "Post-module--post--3a994";
export var widthgrow = "Post-module--widthgrow--45415";